/** @jsx jsx */
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { Box, jsx } from "theme-ui";
import React, { useCallback } from "react";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";

const pageContent =
  '<gatsby_donation amount="0" btntext="Proceed to Registration" donationid="16"' +
  'projectid="" btnbg="#4d8f15" colortext="black" colorprimary="forestgreen"' +
  'colorsecondary="darkgreen" colorbackground="white" colormuted="#f6f6f6"' +
  'colorhighlight="#efeffe" titletext="General Donation SMSF, USA offline" />';
const PageContent = React.memo(PageContentNonMemoized);

const SmsfUSAOffline = () => {
  const eventCallback = useCallback(
    (event) =>
      eventCallbackTriggers("smsf-usa-general-donation-offline", event),
    []
  );

  return (
    <LayoutHome
      seoTitle="General Donation to SMSF, USA offline – Donations"
      contentHeaderStyle="contentHeaderText"
      contentHeader={<h3>Heartfulness Donations</h3>}
    >
      <Box>
        <h3 sx={{ mb: 3, mt: 3 }}>General Donation to SMSF USA offline</h3>
        <p>
          All donations to Sahaj Marg Spirituality Foundation are voluntary.
          Donated funds are used to:
        </p>
        <ul>
          <li>Host seminars and gatherings in the state of Texas.</li>
          <li>
            Support education, research and publications in the field of
            spirituality.
          </li>
          <li>
            Offer grants to similar non-profit organizations to promote
            heartfulness meditation to countries around the globe.
          </li>
        </ul>
        <br />
        Please contact{" "}
        <a href="mailto:us.treasurer@sahajmarg.org">
          info.accounts@sahajmarg.org
        </a>{" "}
        for questions about SMSF donations.
        <PageContent eventCallback={eventCallback} pageContent={pageContent} />
      </Box>
    </LayoutHome>
  );
};

export default SmsfUSAOffline;
